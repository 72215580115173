import { render, staticRenderFns } from "./force-revalidation.vue?vue&type=template&id=488f71c2&scoped=true"
import script from "./force-revalidation.vue?vue&type=script&lang=js"
export * from "./force-revalidation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488f71c2",
  null
  
)

export default component.exports